import { useLocation } from "react-router-dom";
import SelectSearch from "react-select-search";
// import { fuzzySearch } from "react-select-search";
import { useEffect, useState } from "react";
import axios from "axios";

import "react-select-search/style.css";

const CustomSelect = ({
  options,
  currentValue,
  currentIndex = -1,
  name,
  data = [],
  groups = [],
  changeHandler,
  placeholder = "",
  eraseValue = false,
  className = "select-search",
  multiple = false,
  ...rest
}) => {
  const location = useLocation();

  const [opts, setOpts] = useState(options);
  const [value, setValue] = useState(currentValue);

  // Изменение локального состояния при изменении общего состояния
  useEffect(() => {
    setValue(currentValue);
  }, [currentValue]);

  // Изменение локальных опций при изменении глобальных
  useEffect(() => {
    setOpts(options);
  }, [options]);

  useEffect(() => {
    // Сохранение id группы
    if (name === "b_group") {
      options.map((opt) => {
        if (opt.value === value && opt.id) {
          changeHandler(currentIndex, "b_group_id", opt.id);
          return false;
        }
      });
    }

    if (name === "name") {
      // Автоподстановка артикула и группы
      options.forEach((opt) => {
        if (opt.value === value) {
          changeHandler(currentIndex, "article", opt.article);
          changeHandler(currentIndex, "good_id", opt.good_id);
          changeHandler(currentIndex, "b_group_id", opt.b_group_id);
          changeHandler(currentIndex, "measure", opt.measure);

          groups.map((gr) => {
            if (gr.id === opt.b_group_id)
              changeHandler(currentIndex, "b_group", gr.name);
          });
          return false;
        }
      });

      if (data.length > 0 && value !== data[currentIndex].name)
        checkAmount(value);
    }

    if (name === "article") {
      // Автоподстановка наименования и группы
      options.forEach((opt) => {
        if (opt.value === value) {
          if (opt.good) {
            changeHandler(currentIndex, "name", opt.good);
            changeHandler(currentIndex, "good_id", opt.good_id);
            changeHandler(currentIndex, "b_group_id", opt.b_group_id);
            changeHandler(currentIndex, "measure", opt.measure);
          }

          groups.forEach((gr) => {
            if (gr.id === opt.b_group_id)
              changeHandler(currentIndex, "b_group", gr.name);
          });

          return false;
        }
      });

      if (data.length > 0 && value !== data[currentIndex].article)
        checkAmount(data[currentIndex].name);
    }

    if (name === "name_after") {
      options.forEach((opt) => {
        if (opt.value === value) {
          if (opt.article) {
            changeHandler(currentIndex, "article_after", opt.article);
            changeHandler(currentIndex, "good_id_after", opt.good_id);
          }
          return false;
        }
      });
    }

    if (name === "article_after") {
      options.forEach((opt) => {
        if (opt.value === value) {
          if (opt.good) changeHandler(currentIndex, "name_after", opt.good);
          if (opt.good)
            changeHandler(currentIndex, "good_id_after", opt.good_id);
          return false;
        }
      });
    }

    // Изменение общего состояния
    if (currentValue !== value) {
      if (currentIndex === -1) changeHandler(name, value);
      else changeHandler(currentIndex, name, value);
      if (eraseValue) setValue("");
    }
  }, [value, data, name]);
  // currentIndex, currentValue, eraseValue, groups, options

  // Метод проверки остатков
  const checkAmount = (plantName) => {
    let requestData = {
      name: plantName,
      storage: data[0].storage,
    };

    axios.post("fields/checkAmount", requestData).then((response) => {
      if (response.data.amounts.length > 0) {
        if (location.pathname.includes("factory")) {
          if (!data[0].isMaterial) {
            // Исключение для материалов
            response.data.positions = JSON.parse(
              JSON.stringify(response.data.amounts)
            );
            response.data.positions.map((amount, index) => {
              response.data.positions[index].amount_after = "";
              response.data.positions[index].address_after = "";
            });
          }
          changeHandler(currentIndex, "amounts", response.data.amounts);
          changeHandler(currentIndex, "userPositions", response.data.positions);
          changeHandler(
            currentIndex,
            "totalAmount",
            response.data.total_amount
          );
          changeHandler(
            currentIndex,
            "totalAmount_before",
            response.data.total_amount
          );
          changeHandler(currentIndex, "totalAmount_after", 0);
        } else {
          changeHandler(currentIndex, "amounts", response.data.amounts);
          if (response.data.datas)
            changeHandler(currentIndex, "datas", response.data.datas);
          changeHandler(currentIndex, "userPositions", response.data.positions);
          changeHandler(
            currentIndex,
            "totalAmount",
            response.data.total_amount
          );
          changeHandler(
            currentIndex,
            "totalAmount_after",
            response.data.total_amount
          );
        }
      }
    });
  };

  // Обработка снятия фокуса
  const handleBlur = (e) => {
    // Запись состояния, если введено полное совпадение
    if (e.target.value !== "" && !multiple)
      opts.map((opt) => {
        if (e.target.value === opt.value) setValue(e.target.value);
      });
  };

  // Прослойка для изменения значения селекта в зависимости от мультивыбора
  const handleChange = (val) => {
    if (multiple) {
      const copy = Object.assign([], value);
      let spliced = false;
      copy.forEach((v, i) => {
        if (v === val) {
          copy.splice(i, 1);
          spliced = true;
        }
      });
      if (!spliced) copy.push(val);
      setValue(copy);
    } else setValue(val);
  };

  return (
    <SelectSearch
      options={typeof opts === "undefined" ? [] : opts}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      autoComplete="off"
      placeholder={multiple && value !== "" ? value.join(", ") : placeholder}
      className={className}
      emptyMessage={() => <div className="notFound">Не найдено</div>}
      search
      // filterOptions={fuzzySearch}
      { ...rest }
    />
  );
};

export default CustomSelect;
