import {Fragment, useContext} from 'react';
// import {useNavigate} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {toast} from 'react-toastify';

import cl from "styles/pages/[fieldId].module.scss";
import colors from "styles/pages/[boardId].module.scss";

import {setInventoryDataAction} from "redux/slices/inventorySlice";

import {debounce, EMPLOYEE_SETTING_DELAY, VALIDATE_EMPLOYEE_DELAY} from 'helpers/utils';
import {AppContext} from "providers/AppContextProvider";

import CustomSearchSelect from 'components/custom-search-select/custom-search-select';
// import Input from "components/input/InputComponent";
// import CustomSelect from "components/CustomSelect";

const TableRow = ({item, index}) => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  const {alert} = useContext(AppContext);

  const items = useSelector((state) => state.inventory).inventory.data;
  const storage = useSelector((state) => state.inventory).inventory.storage;
  const {groups} = useSelector((state) => state.business);
  const goods = useSelector((state) => state.good).goods;
  const articles = useSelector((state) => state.good).articles;
  const allAddresses = useSelector((state) => state.inventory).addresses.data;
  const addresses = allAddresses.filter((address) => address.storage === storage);

  const plants = goods;
  // article: "52194"
  // b_group_id: "145944"
  // good_id: "443989"
  // measure: "шт."
  // name: "Яблоня гибридная 'Evereste', ком 12-14"
  // value: "Яблоня гибридная 'Evereste', ком 12-14"
  const plantsArticles = articles;
  // b_group_id: "145944"
  // good: "Яблоня гибридная 'Evereste', ком 12-14"
  // good_id: null
  // measure: null
  // name: "52194"
  // value: "52194"
  // console.log(firstItemIndex);
  const itemOrderNumber = index + 1;

  // console.log(`TableRow rerendered: #${itemOrderNumber}`);

  // Фильтрация наименований в зависимости от группы
  // const filterNames = (groupId = 0) => {
  //   if (groupId === 0) {
  //     return plantsNames;
  //   }

  //   const filtered = plantsNames.filter((name) => {
  //     return name.b_group_id === groupId;
  //   });
  //   return filtered.sort((a, b) => {
  //     if (a.name === b.name) return 0;
  //     else return a.name > b.name ? 1 : -1;
  //   });
  // };

  // Получить список наименований для селекта для вновь добавленной строки (в самом низу)
  const filterSelectComponentOptions = (selectComponentName) => {
    const itemsNames = structuredClone(items).map((itm) => itm.name);
    // Отсеиваем те товары, которые уже есть в инвентаризации
    const filteredPlants = plants.filter((plant) => !itemsNames.includes(plant.name));
    const filteredArticles = filteredPlants.map((plant) => ({
      id: plant.b_group_id,
      name: plant.article,
      value: plant.article
    }));

    // Если в новой строке группа уже выбрана, то фильтруем список для селекта ещё и по группе
    if (item.b_group && groups.data.length) {
      const groupId = groups.data.find((gr) => gr.name === item.b_group).id;
      const filteredByGroupPlants = filteredPlants.filter((plant) => plant.b_group_id === groupId);
      const filteredByGroupArticles = filteredByGroupPlants.map((plant) => ({
        id: plant.b_group_id,
        name: plant.article,
        value: plant.article
      }));

      switch(selectComponentName) {
        case 'name':
          return filteredByGroupPlants;
        case 'article':
          return filteredByGroupArticles;
        default:
          return;
      };
    }

    switch(selectComponentName) {
      case 'name':
        return filteredPlants;
      case 'article':
        return filteredArticles;
      default:
        return;
    };
  };

  // Метод изменения наименования и артикула (и группы, если не выбрана)
  const changeValue = (index, selectElementName, value) => {
    if (selectElementName === "datas" && items[index].article === "") return;

    const copiedItems = structuredClone(items);

    // Проверка дублирующей позиции
    let error = false;
    copiedItems.forEach((cpItm) => (cpItm[selectElementName] === value && selectElementName !== "b_group") ? error = true : null);

    if (error) {
      alert("Такой товар уже есть в списке!", "danger");
      copiedItems.splice(index, 1);
    } else {
      copiedItems[index][selectElementName] = value;

      if (selectElementName === 'name') {
        const plant = plants.find((plant) => plant.name === value);
        const plantArticle = plant.article;
        const businessGroupName = groups.data.find((group) => group.id === plant.b_group_id).name;

        copiedItems[index].article = plantArticle;
        copiedItems[index].b_group = businessGroupName;
      }
      if (selectElementName === 'article') {
        const plant = plants.find((plant) => plant.article === value);
        const plantName = plant.name;
        const businessGroupName = groups.data.find((group) => group.id === plant.b_group_id).name;

        copiedItems[index].name = plantName;
        copiedItems[index].b_group = businessGroupName;
      }
    }

    dispatch(setInventoryDataAction(copiedItems));
  };

  // Добавить колонку
  const addDataLine = (index) => {
    const copy = structuredClone(items);
    copy[index].datas.push({
      address_uchet: "",
      amount_uchet: "",
      address_fact: "",
      amount_fact: "",
      spread: "",
    });
    dispatch(setInventoryDataAction(copy));
  };

  // Удаление строки
  const deleteLine = ({index, i}) => {
    const handler = () => {
      const copiedItems = structuredClone(items);
      copiedItems[index].datas.splice(i, 1);

      // Если это последняя подстрока, то из инвентаризации удаляется вся строка
      if (!copiedItems[index].datas.length) {
        copiedItems.splice(index, 1);
      }

      dispatch(setInventoryDataAction(copiedItems));
      alert("Строка удалена", "success", 1000);
    };

    alert("Удалить строку?", "danger", 0, [
      {
        text: "Да",
        handler
      },
      {
        text: "Нет",
        handler: () => {
          alert("", "default", 1);
        },
        needToCloseImmediately: true
      }
    ]);
  };

  // Проверка, есть ли выбранное значение адреса в списке адресов выбранного склада
  const validateSelectedValue = (selectedValue) => {
    const optionsValues = addresses.map((option) => option.value);
    const isSelectedValueIsInOptions = optionsValues.includes(selectedValue);
    const isAnyOptionStartsWithValue = optionsValues.some((option) => option.startsWith(selectedValue));

    if (!isSelectedValueIsInOptions && !isAnyOptionStartsWithValue) {
      toast.error(`Адрес " ${selectedValue} " не содержится в списке адресов склада ${storage} !`);
    }
  };
  const validateSelectedValueDebounced = debounce((arg) => validateSelectedValue(arg), VALIDATE_EMPLOYEE_DELAY);

  // debounced setting into the state
  const setInventoryDataActionDebounced = debounce((arg) => dispatch(setInventoryDataAction(arg)), EMPLOYEE_SETTING_DELAY);

  // Изменение данных в формах
  const tableRowValueChangeHandler = (index, name, value) => {
    if (value && name === 'address_fact') {
      validateSelectedValueDebounced(value);
    }

    const clonedItems = structuredClone(items);
    clonedItems[index.index]["datas"][index.i][name] = value;

    const uchet = Number(clonedItems[index.index]["datas"][index.i]["amount_uchet"]);

    const fact = Number(clonedItems[index.index]["datas"][index.i]["amount_fact"]);

    clonedItems[index.index]["datas"][index.i]["spread"] = fact - uchet;

    // Подсчет общего факта
    let factAll = 0;
    clonedItems[index.index]["datas"].forEach((dt) => {
      factAll += Number(dt.amount_fact);
    });
    clonedItems[index.index].fact = factAll;
    clonedItems[index.index].total_fact = factAll;
    clonedItems[index.index].spread = Number(factAll) - Number(clonedItems[index.index].uchet);

    setInventoryDataActionDebounced(clonedItems);
  };

  return (
    <Fragment>
      {item.datas.map((dt, i) => (
        <tr key={i}>
          {i === 0 && (
            <>
              {/* Ячейка: Номер по порядку */}
              <td className={cl.center} rowSpan={item.datas.length}>
                {itemOrderNumber}
              </td>

              {/* Ячейка: Наименование */}
              <td
                rowSpan={item.datas.length}
                className={cl.itemName}
              >
                {item.name === "" ? (
                  // <CustomSelect
                  //   options={filterNames(item.b_group_id)}
                  //   currentValue={item.name}
                  //   currentIndex={index}
                  //   name="name"
                  //   data={items}
                  //   groups={groups.data}
                  //   changeHandler={changeName}
                  //   placeholder="Наименование"
                  // />
                  <CustomSearchSelect
                    inputName='name'
                    defaultValue={''}
                    options={filterSelectComponentOptions('name')}
                    onChange={(value) => changeValue(index, 'name', value)}
                    placeholder='Наименование'
                  />
                  // <SelectSearch
                  //   options={filterSelectComponentOptions('name')}
                  //   value=''
                  //   onChange={(value) => changeValue(index, 'name', value)}
                  //   autoComplete="off"
                  //   placeholder="Наименование"
                  //   search
                  // />
                ) : (
                  <span
                    style={{ cursor: "pointer" }}
                    // onClick={() =>
                    //   navigate("/history?name=" + item.name)
                    // }
                  >
                    {item.name}
                  </span>
                )}
              </td>

              {/* Ячейка: Артикул */}
              <td
                rowSpan={item.datas.length}
              >
                {item.name === "" ? (
                  // <CustomSelect
                  //   options={filterArticles(item.b_group_id)}
                  //   currentValue={item.article}
                  //   groups={groups.data}
                  //   currentIndex={index}
                  //   name="article"
                  //   data={items}
                  //   changeHandler={changeName}
                  //   placeholder="Артикул"
                  // />
                  // <SelectSearch
                  //   options={filterSelectComponentOptions('article')}
                  //   value={item.article}
                  //   onChange={(value) => changeValue(index, 'article', value)}
                  //   autoComplete="off"
                  //   placeholder="Артикул"
                  //   search
                  // />
                  <CustomSearchSelect
                    inputName='article'
                    defaultValue={''}
                    options={filterSelectComponentOptions('article')}
                    onChange={(value) => changeValue(index, 'article', value)}
                    placeholder='Артикул'
                  />
                ) : (
                  <span
                    style={{ cursor: "pointer" }}
                    // onClick={() =>
                    //   navigate("/history?name=" + item.name)
                    // }
                  >
                    {item.article}
                  </span>
                )}
              </td>

              {/* Ячейка: Группа из Бизнес.ру */}
              <td
                rowSpan={item.datas.length}
                className={cl.itemGroup}
              >
                {item.name === "" ? (                  
                  // <CustomSelect
                  //   options={groups.data}
                  //   currentValue={item.b_group}
                  //   currentIndex={index}
                  //   name="b_group"
                  //   data={items}
                  //   changeHandler={changeName}
                  //   placeholder="Группа"
                  // />
                  // <SelectSearch
                  //   options={groups.data}
                  //   value={item.b_group}
                  //   onChange={(value) => changeValue(index, 'b_group', value)}
                  //   autoComplete="off"
                  //   placeholder="Группа"
                  //   search
                  // />
                  <CustomSearchSelect
                    inputName='b_group'
                    defaultValue={item.b_group}
                    options={groups.data}
                    onChange={(value) => changeValue(index, 'b_group', value)}
                    placeholder='Группа'
                  />
                ) : (
                  item.b_group
                )}
              </td>

              {/* Ячейка: Учёт (из xls файла) */}
              <td className={cl.right} rowSpan={item.datas.length}>
                {new Intl.NumberFormat("ru-RU").format(item.uchet)}
              </td>

              {/* Ячейка: Факт (из xls файла) */}
              <td className={cl.right} rowSpan={item.datas.length}>
                {new Intl.NumberFormat("ru-RU").format(item.fact)}
              </td>

              {/* Ячейка: Отклонение (из xls файла) */}
              <td className={cl.right} rowSpan={item.datas.length}>
                {new Intl.NumberFormat("ru-RU").format(item.spread)}
              </td>

              {/* Ячейка: Всего учёт */}
              <td
                className={cl.right + " " + colors.b7}
                rowSpan={item.datas.length}
              >
                {new Intl.NumberFormat("ru-RU").format(
                  item.total_uchet
                )}
              </td>
            </>
          )}

          {/* Ячейка: Адрес */}
          <td
            onClick={() =>
              tableRowValueChangeHandler(
                {index, i},
                "address_fact",
                dt.address_uchet
              )
            }
            className={colors.b7 + " " + cl.address}
          >
            {dt.address_uchet}
          </td>

          {/* Ячейка: Кол-во */}
          <td
            onClick={() =>
              tableRowValueChangeHandler(
                {index, i},
                "amount_fact",
                dt.amount_uchet
              )
            }
            className={colors.b7 + " " + cl.right}
          >
            {new Intl.NumberFormat("ru-RU").format(dt.amount_uchet)}
          </td>

          {/* Ячейка: Всего факт */}
          {i === 0 && (
            <td
              className={cl.right + " " + colors.b5}
              rowSpan={item.datas.length}
            >
              {new Intl.NumberFormat("ru-RU").format(item.total_fact)}
            </td>
          )}

          {/* Ячейка: Адрес */}
          <td className={colors.b5 + " " + cl.address}>
            {item.provided === 1 ? (
              dt.address_fact
            ) : (
              // <CustomSelect
              //   options={addresses}
              //   currentValue={dt.address_fact}
              //   currentIndex={{index, i}}
              //   name="address_fact"
              //   changeHandler={tableRowValueChangeHandler}
              //   placeholder="Адрес"
              // />
              // <SelectSearch
              //   options={addresses}
              //   value={dt.address_fact ? dt.address_fact : dt.address_uchet}
              //   onChange={(value) => tableRowValueChangeHandler({index, i}, 'address_fact', value)}
              //   autoComplete="off"
              //   placeholder="Адрес"
              //   search
              // />
              <CustomSearchSelect
                inputName='address_fact'
                defaultValue={dt.address_fact ? dt.address_fact : dt.address_uchet}
                options={addresses}
                onChange={(value) => tableRowValueChangeHandler({index, i}, 'address_fact', value)}
                executeChangeHandlerOnTypingValue={true}
                placeholder='Адрес'
              />
            )}
          </td>

          {/* Ячейка: Кол-во факт (Input) */}
          <td className={colors.b5 + " " + cl.right}>
            {item.provided === 1 ? (
              new Intl.NumberFormat("ru-RU").format(dt.amount_fact)
            ) : (
              // <Input
              //   type="text"
              //   name="amount_fact"
              //   placeholder="Факт"
              //   value={dt.amount_fact}
              //   setValue={tableRowValueChangeHandler}
              //   currentIndex={i}
              // />

              <label className={cl.inputLabel}>
                <input
                  type="text"
                  name="amount_fact"
                  defaultValue={dt.amount_fact ?? ''}
                  placeholder="Факт"
                  autoComplete='off'
                  onChange={(evt) => tableRowValueChangeHandler({index, i}, 'amount_fact', evt.currentTarget.value)}
                />
              </label>
            )}
          </td>

          {/* Ячейка: Отклонение */}
          <td className={colors.b5 + " " + cl.right}>
            {new Intl.NumberFormat("ru-RU").format(dt.spread)}
          </td>

          {/* Ячейка: Всего отклонение */}
          {i === 0 && (
            <td
              className={cl.right + " " + colors.b5}
              rowSpan={item.datas.length}
            >
              {!item.total_spread
                ? 0
                : new Intl.NumberFormat("ru-RU").format(
                    item.total_spread
                  )}
            </td>
          )}

          {/* КНОПКИ В КОНЦЕ СТРОКИ */}
          {/* Удалить строку */}
          {!item.provided && (
            <td className="iconed">
              <span
                className="roundIcon material-icons"
                onClick={() => deleteLine({index, i})}
                title="Удалить строку"
              >
                delete_outline
              </span>
            </td>
          )}

          {/* Добавить строку */}
          {i === 0 && !item.provided && (
            <td
              className={"iconed " + cl.bottom}
              rowSpan={item.datas.length}
            >
              <span
                className="roundIcon material-icons"
                onClick={() => addDataLine(index)}
                title="Добавить строку"
              >
                add
              </span>
            </td>
          )}
        </tr>
      ))}
    </Fragment>
  );
};

// export default memo(TableRow, (prevProps, nextProps) => {
//   return prevProps.item.datas.every((row, index) => {
//     const prevAmountFact = Number(row.amount_fact);
//     const nextAmountFact = Number(nextProps.item.datas[index].amount_fact);
//     return prevAmountFact === nextAmountFact;
//   });
  // const isPrevAndNextItemsEqual = JSON.stringify(prevProps.item.datas) === JSON.stringify(nextProps.item.datas);
  // return isPrevAndNextItemsEqual;
// });

export default TableRow;
