import {useState, useRef} from 'react';
import clsx from 'clsx';

import style from './styles.module.scss';

const CustomSearchSelect = ({
  defaultValue = '',
  options,
  inputName,
  onChange,
  placeholder,
  executeChangeHandlerOnTypingValue
}) => {
  const [innerValue, setInnerValue] = useState(defaultValue);
  const [optionsListOpened, setOptionsListOpened] = useState(false);

  const selectWrapperElementRef = useRef();
  const inputElementRef = useRef();

  const renderedOptions = innerValue && options.some((option) => option.value.startsWith(innerValue))
    ? [
      ...options.filter((option) => option.value.startsWith(innerValue)),
      ...options.filter((option) => !option.value.startsWith(innerValue)).sort((a, b) => a.name > b.name)
    ]
    : innerValue && !options.every((option) => option.value.startsWith(innerValue))
        ? options.filter((option) => option.value.startsWith(innerValue))
        : [...structuredClone(options).sort((a, b) => a.name > b.name)];

  const removeEventListeners = () => {
    window.removeEventListener('keydown', escKeyDownHandler);
    window.removeEventListener('click', outsideClickHandler);
  };

  const escKeyDownHandler = (evt) => {
    if (evt.key === 'Escape') {
      setOptionsListOpened(false);
    }
  };

  const outsideClickHandler = (evt) => {
    const composedPath = evt.composedPath();
    const selectElement = selectWrapperElementRef.current;
    const isClickOutOfSelect = !composedPath.includes(selectElement);

    if (isClickOutOfSelect) {
      setOptionsListOpened(false);
    }
  }

  const handleHeaderClick = () => {
    setOptionsListOpened(true);

    window.addEventListener('keydown', escKeyDownHandler);
    window.addEventListener('click', outsideClickHandler);
  };

  const handleInputChange = (evt) => {
    const inputValue = evt.currentTarget.value;

    setInnerValue(inputValue);

    if (executeChangeHandlerOnTypingValue) {
      onChange(inputValue);
    }

    removeEventListeners();
  };

  const handleOptionElementClick = (optionValue) => {
    setInnerValue(optionValue);
    onChange(optionValue);
    setOptionsListOpened(false);

    removeEventListeners();
  };

  return (
    <div
      ref={selectWrapperElementRef}
      className={clsx(style.wrapper, {
        [style.opened]: optionsListOpened,
      })}
    >
      <div
        className={style.header}
        onClick={handleHeaderClick}
      >
        <input
          ref={inputElementRef}
          value={innerValue}
          onChange={handleInputChange}
          className={style.input}
          type="text"
          name={inputName}
          placeholder={placeholder}
          autoComplete='off'
        />
      </div>
      <ul className={style.list}>
        {
          renderedOptions.length
            ? renderedOptions.map((option) => (
                <li
                  key={option.name}
                  className={style.option}
                  value={option.value}
                  onClick={() => handleOptionElementClick(option.value)}
                >
                  {option.name}
                </li>
              ))
            : (
              <li className={style.empty}>Нет вариантов выбора</li>
            )
        }
      </ul>
    </div>
  );
};

export default CustomSearchSelect;
