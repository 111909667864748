import axios from 'axios';
import {toast} from 'react-toastify';
import {StatusCodes} from 'http-status-codes';

import {getToken} from 'helpers/storage';

const BASE_URL = process.env.REACT_APP_SERVER_ENTRYPOINT;
const REQUEST_TIMEOUT = 300000;

const StatusCodeMapping = {
  [StatusCodes.FORBIDDEN]: true,
  [StatusCodes.BAD_REQUEST]: true,
  [StatusCodes.UNAUTHORIZED]: true,
  [StatusCodes.NOT_FOUND]: true,
  [StatusCodes.INTERNAL_SERVER_ERROR]: true
};

const shouldDisplayError = (response) => !!StatusCodeMapping[response.status];

export const createAPI = () => {
  const api = axios.create({
    baseURL: BASE_URL,
    timeout: REQUEST_TIMEOUT
  });

  api.interceptors.request.use(
    (config) => {
      const token = getToken();

      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }

      return config;
    },
  );

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && shouldDisplayError(error.response)) {
        toast.warn(error.response.statusText, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored'
        });
      }
      throw error;
    }
  );
  
  return api;
};
