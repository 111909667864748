import {useMapEvents} from "react-leaflet";
import axios from "axios";
import {useEffect, useState} from "react";

const MapHandlers = ({fields, setFields, Map, AllFields, isDrawing, setIsDrawing}) => {
	// Локальное состояние со всеми полями
	const [allFields, setAllFields] = useState(AllFields.data);
	
	// Параметры текущей линии для постоянного обновления
	const [coords, setCoords] = useState([]);
	
	// Запись координат
	useEffect(() => {
		if(!isDrawing) return;
		
		const f = Object.assign([], fields);
		f[f.length - 1].coords = coords;
		setFields(f);
	}, [coords]);
	
	// Редактирование положения карты
	const setMap = (map) => {
		const zoom = map.getZoom();
		const position = map.getCenter();
		axios.post("map/edit/" + Map.id, {
			position: position,
			zoom: zoom
		});
	}
	
	const map = useMapEvents({
		// Вкл/выкл режим рисования
		dblclick: (e) => {
			// Проверка на редактирование полей
			const checker = fields.filter(fl => fl.isEditing === true);
			if(checker.length > 0){
				setIsDrawing(false);
				return;
			}
			
			if(isDrawing){
				setIsDrawing(false);
				setCoords([]);
				
				// Обновление полей в БД
				axios.post("map/setFields/" + Map.id, {
					fields: fields
				}).then(response => {
					const f = Object.assign([], fields);
					f[f.length - 1].field_id = response.data.id;
					setFields(f);
				});
			} else{
				fields.push({
					id: fields.length > 0 ? fields[fields.length - 1].id + 1 : 1, // Нумерация с 1 для вида
					name: allFields.length > 0 ? (allFields[allFields.length - 1].field_id + 1).toString() : "1",
					coords: coords
				});
				
				// Добавление новой отрисовки ко всем полям
				const allCopy = Object.assign([], allFields);
				allCopy.push({
					field_id: allFields[allFields.length - 1].field_id + 1,
					name: allFields[allFields.length - 1].field_id + 1
				});
				setAllFields(allCopy);
				
				// Получение текущих координат
				const c = map.mouseEventToLatLng(e.originalEvent);
				
				// Первая точка
				coords.push([c.lat, c.lng]);
				
				// Следующая точка для изменения
				coords.push([c.lat, c.lng]);
				
				setIsDrawing(true);
			}
		},
		// Установка новой вершины фигуры
		click: (e) => {
			if(!isDrawing) return;
			
			const c = map.mouseEventToLatLng(e.originalEvent);
			
			const cord = Object.assign([], coords);
			cord.push([c.lat, c.lng]);
			setCoords(cord);
		},
		// Привязка линии к мыши
		mousemove: (e) => {
			if(!isDrawing) return;
			
			const c = map.mouseEventToLatLng(e.originalEvent);
			const cord = Object.assign([], coords);
			
			// Перезапись последних координат для ровной линии
			cord[cord.length - 1] = [c.lat, c.lng];
			/*// Рисование любых линий с зажатым shift
			if(e.originalEvent.shiftKey)
				cord.push([c.lat, c.lng]);
			else // Перезапись последних координат для ровной линии
				cord[cord.length - 1] = [c.lat, c.lng];*/
			
			setCoords(cord);
		},
		// Обработка событий нажатия кнопок
		keydown: (e) => {
			if(!isDrawing) return;
			
			// Удаление последней отрисовки по нажатию Esc
			if(e.originalEvent.key === "Escape" || e.originalEvent.key === "Esc"){
				setIsDrawing(false);
				setCoords([]);
				
				const f = Object.assign([], fields);
				f.pop();
				setFields(f);
			}
		},
		zoomend: () => {
			setMap(map);
		},
		dragend: () => {
			setMap(map);
		}
	});
	
	return null;
};

export default MapHandlers;