import {useSelector, useDispatch} from 'react-redux';

import {setInventoryDataAction} from "redux/slices/inventorySlice";

import TableRow from 'components/table-row/table-row';

const TableBody = ({
  firstItemIndex,
  displayedItems,
  activePage,
  pagesCount
}) => {
  const dispatch = useDispatch();

  // Выборка данных из стейта селекторами
  const inventoryItems = useSelector((state) => state.inventory).inventory.data;

  // Новая строка
  const addLine = () => {
    const copy = structuredClone(inventoryItems);
    copy.push({
      name: "",
      uchet: 0,
      fact: 0,
      spread: 0,
      total_uchet: 0,
      total_fact: 0,
      datas: [
        {
          spread: "",
          amount_fact: "",
          address_fact: "",
          amount_uchet: "",
          address_uchet: "",
        },
      ],
      inventory_id: copy[0].inventory_id,
      map_id: copy[0].map_id,
    });
    dispatch(setInventoryDataAction(copy));
  };
  
  return displayedItems.length && (
    <tbody>
      {displayedItems.map((item, index) => (
        <TableRow
          key={item.id}
          item={item}
          index={firstItemIndex + index}
        />
      ))}

      {/* Добавить наименование */}
      {
        inventoryItems.length > 0 &&
        inventoryItems[0].provided === 0 &&
        activePage === pagesCount && (
          <tr className="adding">
            <td className="iconed">
              <span
                className="roundIcon material-icons"
                onClick={addLine}
                title="Добавить наименование"
              >
                add
              </span>
            </td>
          </tr>
        )
      }
      <tr style={{ height: "1px" }} />
    </tbody>
  );
};

export default TableBody;
