import React from "react";
import { Provider } from "react-redux";

import AppContextProvider from "providers/AppContextProvider";
import { store } from "redux/store";
import Router from "./Router";

import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => (
  <Provider store={store}>
    <AppContextProvider>
      <Router />
      <ToastContainer limit={3} />
    </AppContextProvider>
  </Provider>
);

export default App;
