
import {useContext, useEffect} from "react";

import InventoryTable from "components/archive/InventoryTable";

import {AppContext} from "providers/AppContextProvider";

const Index = ({children}) => {
	const {alert, setCrumbs} = useContext(AppContext);

	// Запись хлебных крошек
	useEffect(() => {
		setCrumbs([
			{name: "Архивы", url: "/archive"},
			{name: "Инвентаризация", url: ""}
		]);
	}, []);
	
	return (
		<>
			
				<title>Архив | инвентаризации</title>
			
			
			{children}
			
			<div className="cabinetMain">
				<InventoryTable alert={alert}/>
			</div>
		</>
	);
};

export default Index;