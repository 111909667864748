import React from "react";
import {
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";

import Login from "pages/login";

import Maps from "pages/map";
import MapAdd from "pages/map/add";
import MapEdit from "pages/map/edit";
import MapId from "pages/map/id";
import MapField from "pages/map/field";

import Inventory from "pages/inventory";
import InventoryAdd from "pages/inventory/add";
import InventoryEdit from "pages/inventory/edit";

import JournalAmount from "pages/journals/amount";
import JournalSpread from "pages/journals/spread";

import Supplies from "pages/supplies";
import SuppliesId from "pages/supplies/id";

import Realizations from "pages/realizations";

import Form from "pages/form";
import FormId from "pages/form/id";
import FormNew from "pages/form/new";

import Factories from "pages/factory";
import FactoryNew from "pages/factory/new";
import FactoryId from "pages/factory/id";

import Archive from "pages/archive";
import ArchiveHistory from "pages/archive/history";
import ArchiveInventory from "pages/archive/inventory";

import CabinetSettings from "pages/cabinet/settings";
import CabinetUsers from "pages/cabinet/users";

import Faq from "pages/faq";
import FaqJournalSpread from "pages/journals/spread";

import HistorySelect from "pages/history/select";
import HistoryItem from "pages/history";

import MainContainerComponent from "components/layout/MainContainerComponent";
import CustomRouter from "components/custom-router/custom-router";
import browserHistory from "components/custom-router/browser-history";

import { getToken } from "helpers/storage";
import { auth, maps, inventory, journal, archive, doc, cabinet, faq, history } from "constants/routes";

const PrivateRoute = () => {
  const accessToken = getToken();

  return (
    accessToken ? (
      <MainContainerComponent>
        <Outlet />
      </MainContainerComponent>
    ) : (
      <Navigate to={auth.LOGIN} />
    )
  );
};

const Router = () => {
  return (
    <CustomRouter history={browserHistory}>
      <Routes>
        <Route exact path="/" element={<PrivateRoute />}>
          <Route exact path="/" element={<Maps />} />
          <Route path={maps.ADD} element={<MapAdd />} />
          <Route path={maps.EDIT} element={<MapEdit />} />
          <Route path={maps.ID} element={<MapId />} />
          <Route path={maps.FIELD} element={<MapField />} />

          <Route path={inventory.ALL} element={<Inventory />} />
          <Route path={inventory.ADD} element={<InventoryAdd />} />
          <Route path={inventory.EDIT} element={<InventoryEdit />} />

          <Route path={journal.AMOUNT} element={<JournalAmount />} />
          <Route path={journal.SPREAD} element={<JournalSpread />} />

          <Route path={doc.SUPPLIES} element={<Supplies />} />
          <Route path={doc.SUPPLIES_ID} element={<SuppliesId />} />

          <Route path={doc.REALIZATIONS} element={<Realizations />} />

          <Route path={doc.FORM} element={<Form />} />
          <Route path={doc.FORM_NEW} element={<FormNew />} />
          <Route path={doc.FORM_ID} element={<FormId />} />

          <Route path={doc.FACTORIES} element={<Factories />} />
          <Route path={doc.FACTORY_NEW} element={<FactoryNew />} />
          <Route path={doc.FACTORY_ID} element={<FactoryId />} />

          <Route path={archive.ALL} element={<Archive />} />
          <Route path={archive.HISTORY} element={<ArchiveHistory />} />
          <Route path={archive.HISTORY_ID} element={<HistorySelect />} />
          <Route path={archive.HISTORY_ITEM} element={<HistoryItem />} />
          <Route path={archive.INVENTORY} element={<ArchiveInventory />} />
          <Route path={archive.INVENTORY_ID} element={<InventoryEdit />} />
  
          <Route path={cabinet.SETTINGS} element={<CabinetSettings />} />
          <Route path={cabinet.USERS} element={<CabinetUsers />} />

          <Route path={faq.ALL} element={<Faq />} />
          <Route path={faq.JOURNAL_SPREAD} element={<FaqJournalSpread />} />

          <Route path={history.SELECT} element={<HistorySelect />} />
          <Route path={history.ITEM} element={<HistoryItem />} />
        </Route>

        <Route exact path={auth.LOGIN} element={<Login />} />
      </Routes>
    </CustomRouter>
  );
};

export default Router;
