const auth = {
  LOGIN: "/login",
  FORGOT: "/forgot",
  SIGNUP: "/signup",  
};

const maps = {
  ID: "/map/:id",
  ADD: "/map/add",
  EDIT: "/map/edit/:id",
  FIELD: "/map/:id/fields/:field_id",
};

const journal = {
  AMOUNT: "/journals/amount",
  SPREAD: "/journals/spread",
};

const inventory = {
  ALL: "/inventory",
  ADD: "/inventory/add",
  EDIT: "/inventory/edit/:id",
};

const archive = {
  ALL: "/archive",
  HISTORY: "/archive/history",
  HISTORY_ID: "/archive/history/:id",
  HISTORY_ITEM: "/archive/history/:id/item",
  FORM: "/archive/form", // <?type=Списание|Оприходование|Перемещение>|<?correction=1>
  SUPPLIES: "/archive/supplies",
  REALIZATIONS: "/archive/realizations",
  INVENTORY: "/archive/inventory",
  INVENTORY_ID: "/archive/inventory/:id",
  FACTORY: "/archive/factory",
};

const doc = {
  SUPPLIES: "/supplies",
  SUPPLIES_ID: "/supplies/:id",
  REALIZATIONS: "/realizations",
  FORM: "/form", // <?type=Списание|Оприходование|Перемещение>
  FORM_NEW: "/form/new",
  FORM_ID: "/form/:id", // <?type=Списание|Оприходование|Перемещение>
  FACTORIES: "/factory",
  FACTORY_NEW: "/factory/new",
  FACTORY_ID: "/factory/:id",
};

const cabinet = {
  SETTINGS: "/cabinet/settings",
  USERS: "/cabinet/users",
};

const faq = {
  ALL: "/faq",
  JOURNAL_SPREAD: "/faq/journalSpread",
  INVENTORY: "/faq/inventory",
};

const history = {
  SELECT: "/history/select",
  ITEM: "/history",
};

export {
  auth,
  maps,
  journal,
  inventory,
  archive,
  doc,
  cabinet,
  faq,
  history,
};
