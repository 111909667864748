import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';

import {Good} from 'constants/endpoints';

const syncGoods = createAsyncThunk(
  'good/sync',
  async (_args, {dispatch, extra: api}) => {
    await api.get(Good.SYNC);
  }
);

const fetchGoods = createAsyncThunk(
  'good/all',
  async (_args, {dispatch, extra: api}) => {
    const {data} = await api.get(Good.ALL);
    return data;
  }
);

const initialState = {
  goods: [],
  articles: []
};

export const goodSlice = createSlice({
  name: 'good',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchGoods.fulfilled, (state, action) => {
        state.goods = action.payload.result.goods;
        state.articles = action.payload.result.articles;
      });
  }
});

export {
  fetchGoods,
  syncGoods
};
