import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";

import {loadingStatus} from "helpers/fetcher";

import {History} from "constants/endpoints";

const fetchCurrentNames = createAsyncThunk(
  'history/getCurrentNames',
  async (_args, {dispatch, extra: api}) => {
    const {data} = await api.get(History.CURRENT_NAMES);
    return data;
  }
);

const fetchArchiveNames = createAsyncThunk(
  'history/getArchiveNames',
  async (id, {dispatch, extra: api}) => {
    const {data} = await api.get(`${History.ARCHIVE_NAMES}/${id}`);
    return data;
  }
);


const initialState = {
  currentNames: {
    status: loadingStatus.IDLE,
    data: [],
    error: null,
  },
  archiveNames: {
    status: loadingStatus.IDLE,
    data: [],
    dates: '',
    error: null,
  }
};

export const historySlice = createSlice({
  name: "history",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCurrentNames.pending, (state) => {
        state.currentNames.status = loadingStatus.LOADING;
      })
      .addCase(fetchCurrentNames.fulfilled, (state, action) => {
        state.currentNames.status = loadingStatus.SUCCEEDED;
        state.currentNames.data = action.payload.result;
      })
      .addCase(fetchCurrentNames.rejected, (state, action) => {
        state.currentNames.status = loadingStatus.FAILED;
        state.currentNames.error = `${action.error.name}: ${action.error.message}`;
      })
      .addCase(fetchArchiveNames.pending, (state) => {
        state.archiveNames.status = loadingStatus.LOADING;
      })
      .addCase(fetchArchiveNames.fulfilled, (state, action) => {
        state.archiveNames.status = loadingStatus.SUCCEEDED;
        state.archiveNames.data = action.payload.result;
        state.archiveNames.dates = action.payload.dates;
      })
      .addCase(fetchArchiveNames.rejected, (state, action) => {
        state.archiveNames.status = loadingStatus.FAILED;
        state.archiveNames.error = `${action.error.name}: ${action.error.message}`;
      });
  }
});

export {
  fetchCurrentNames,
  fetchArchiveNames
};
