import {combineReducers} from '@reduxjs/toolkit';

import {userSlice} from "redux/slices/userSlice";
import {mapSlice} from "redux/slices/mapSlice";
import {fieldSlice} from "redux/slices/fieldSlice";
import {journalSlice} from "redux/slices/journalSlice";
import {inventorySlice} from './slices/inventorySlice';
import {businessSlice} from "redux/slices/businessSlice";
import {docSlice} from "redux/slices/docSlice";
import {historySlice} from "redux/slices/historySlice";
import {goodSlice} from './slices/goodSlice';

export const rootReducer = combineReducers({
  user: userSlice.reducer,
  map: mapSlice.reducer,
  field: fieldSlice.reducer,
  journal: journalSlice.reducer,
  inventory: inventorySlice.reducer,
  good: goodSlice.reducer,
  business: businessSlice.reducer,
  doc: docSlice.reducer,
  history: historySlice.reducer,
});
